import React from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import RowWrapper from '../utils/rowWrapper';
import Explore from '../utils/explore';
import gsap from 'gsap';
import { theme } from "../utils/theme"

import Point from '../components/points/point';


const Container = styled.div`
  /* height: 100vh; */
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
  overflow-y: visible;
  overflow-x: hidden;
`

const LandingContent = styled(RowWrapper)`
 height: 100vh;
`

const Center = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  display: flex;
  align-items: center;

  @media ${props => props.theme.breaks.down('md')} {
    flex-direction: column-reverse;
    top: 50%;
  }
`

const ExContainer = styled.div`
  flex-grow: 1;
  margin-right: 40px;

  @media ${props => props.theme.breaks.down('md')} {
    margin-right: 0;
  }
`

const Title = styled.h1`
  display: block;
  font-size: 7rem;
  font-weight: 300;
  width: 400px;
  max-width: 90%;
  line-height: 1.1;
  color: ${props => props.theme.colors.body};

  @media ${props => props.theme.breaks.down('md')} {
    font-size: 4rem;
    text-align: center;
    margin-bottom: 3rem;
  }
`

const TitleCond = styled.h5`
  font-size: 3rem;
  font-weight: 300;
  width: 180px;
  line-height: 1.1;
  color: ${props => props.theme.colors.body};
  margin-bottom: 150px;
  text-align: left;

  @media ${props => props.theme.breaks.down('md')} {
    width: 50%;
    max-width: 300px;
    font-size: 2rem;
    margin: 5rem 0 10rem 0;
  }
`

const PointSection = styled.div`
  height: 100vh;
  padding-top: 50px;
  text-align: center;
  padding-left: 50px;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;

    .title-cond {
      width: 90% !important;
    }
  }
`

const PointContainer = styled.div`
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  margin-top: 7rem;
  text-align: left;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
  }
`

const FixedFooter = styled.div`
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  position: fixed;
  /* left: 50%;
  transform: translateX(-50%); */
  height: 100px;
  padding-left: 40px;
  bottom: 20px;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
    bottom: 0;
  }
`


const CantSeePage = ({ data }) => {

  const scroll = () => {
    gsap.to(window, {scrollTo: '.point-sec'})
  }

  // const {HeroImage, Heading1, Heading2} = data.strapiHero;
  return (
    
      <Layout color={theme.colors.body} pageTitle="Things you can't see.">
      <Container>
        <LandingContent className="sec-0">
          <Center>
            <ExContainer>
              <Explore click={() => scroll()} sec="couch" sub="1" show top="30%" bg={theme.colors.body} col={theme.colors.dark} />
            </ExContainer>
            <Title>There’s more to branding than good design.</Title>
          </Center>
        </LandingContent>
        
        <PointSection className="point-sec">
          <div className="title-cond" style={{width: '60%', margin: '0 auto'}}>
          <TitleCond>There’s more to branding than good design.</TitleCond>
          </div>
          <PointContainer>
            <Point num='01'>Your brand is how people instinctively feel about your company.</Point>
            <Point num='02'>Everything your company does will reinforce your brand or it won’t.</Point>
            <Point num='03'>Your brand philosophy is not a logo or a color palette — it's the thing you can't see. And it's what strengthens your brand a lot more than the things you can see.</Point>
          </PointContainer>
          
        </PointSection>

         <FixedFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </FixedFooter>
        
      </Container>
      </Layout>
    
)
}

export default CantSeePage;